.menu ul{
    list-style-type: none;
}
.menu ul li{
    display:block;
    float: left;
}
.menu a{
    color: #fff;
    text-decoration: none
}
.logo img{max-width: 175px;}