.amazonProduct{
    width:120px;
    height:240px;
    display:block
}

.category{
    color:#fff;
    background: #0A3041;
    text-decoration:none;
    width:100%;
    display:block;
    text-align:center;
    border-radius: 10px;
    font-size:1.3rem;
}